import React from 'react';

import { EBalconyStatus } from 'enums/order';

import formatDate from 'date-fns/format';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { statusToUpper } from 'helpers/balcony';

import { useStyles } from './styles';

interface IProps {
  status: EBalconyStatus;
  date: string;
  color: string;
}

export default function Timeline(props: IProps): JSX.Element {
  const { status, date, color } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={`${classes.content} ${color}`}
    >
      <Typography variant="caption" className={classes.status} align="center">
        {statusToUpper(status)}
      </Typography>
      <Typography variant="caption" className={classes.date} align="center">
        {date ? formatDate(new Date(date), 'dd/MM/yyyy hh:mm') : '-'}
      </Typography>
    </Grid>
  );
}
