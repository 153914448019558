import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { fetchProductsGeneral } from 'redux/ducks/stock';
import IStore from 'interfaces/store';

import Loading from 'components/Common/Loading';
import ProductsTable from './ProductsTable';

export default function Product(): JSX.Element {
  const dispatch = useDispatch();
  const { stock } = useSelector<IStore, IStore>((state) => state);

  useEffect(() => {
      dispatch(fetchProductsGeneral());
  }, [dispatch]);

  return (
    <>
      <Loading show={stock.fetch} />
      <Grid container spacing={3}>
        <ProductsTable products={stock.products} />
      </Grid>
    </>
  );
}
